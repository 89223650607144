// sass-lint:disable no-duplicate-properties

//CONVERTS PX TO REM
@function calculate-rem($size) {
  $rem-size: $size / 16px;
  @return #{$rem-size}rem;
}

//MIXIN FONT SIZE. REM + PX FALLBACK
@mixin font-size($size) {
  font-size: #{$size};
  font-size: calculate-rem($size);
}

/**
 * Add horizontal margin to elements.
 *
 * @mixin
 * @param $sx the margin left value. Default value is `auto`
 * @param $dx the margin right value. Default value is `auto`
 * @example
 *  @include hmargin(3px);
 *  @include hmargin(3px, 4px);
 */
@mixin hmargin($sx: auto, $dx: auto) {
  margin-left: $sx;
  margin-right: $dx;
}

/**
 * Add vertical margin to elements.
 *
 * @mixin
 * @param $top the margin top value. Default value is `auto`
 * @param $bottom the margin bottom value. Default value is `auto`
 * @example
 *  @include vmargin(3px);
 *  @include vmargin(3px, 4px);
 */
@mixin vmargin($top: auto, $bottom: auto) {
  margin-bottom: $bottom;
  margin-top: $top;
}

/**
 * Add horizontal padding to elements.
 *
 * @mixin
 * @param $sx the padding left value. Default value is `auto`
 * @param $dx the padding right value. Default value is `auto`
 * @example
 *  @include hmargin(3px);
 *  @include hmargin(3px, 4px);
 */
@mixin hpadding($sx: auto, $dx: auto) {
  padding-left: $sx;
  padding-right: $dx;
}

/**
 * Add vertical padding to elements.
 *
 * @mixin
 * @param $top the margin left value. Default value is `auto`
 * @param $bottom the margin right value. Default value is `auto`
 * @example
 *  @include hmargin(3px);
 *  @include hmargin(3px, 4px);
 */
@mixin vpadding($top: auto, $bottom: auto) {
  padding-bottom: $bottom;
  padding-top: $top;
}

// sass-lint:enable no-duplicate-properties

@mixin subject-color($subject-map) {
  @each $subject in $subject-map {
    .deaflix-learning-object-card--#{$subject} {
      .deaflix-learning-object-card__background {
        @apply after:bg-#{$subject}-secondary
        before:bg-#{$subject}-secondary
        bg-[linear-gradient(225deg,_theme("colors.#{$subject}.primary")_0%,_theme("colors.#{$subject}.primary")_30%,_theme("colors.#{$subject}.secondary")_100%)]; //sass-lint:disable-line space-after-comma
      }
    }
  }
}

@mixin subject-color-locked($subject-map) {
  @each $subject in $subject-map {
    &.deaflix-learning-object-card--#{$subject} {
      .deaflix-learning-object-card__background {
        @apply bg-#{$subject}-tertiary;
      }
    }
  }
}

@mixin option-filter-subject-selected($subject-map) {
  @each $subject in $subject-map {
    &.deaflix-select-filter__option--#{$subject} {
      @apply bg-#{$subject}-primary;
    }
  }
}

@mixin option-filter-subject-hover($subject-map) {
  @each $subject in $subject-map {
    &.deaflix-select-filter__option--#{$subject} {
      @apply bg-#{$subject}-secondary/30;
    }
  }
}

@mixin deaflix-search-filter-button($subject-map) {
  @each $subject in $subject-map {
    &.deaflix-select-filter__button--#{$subject} {
      @apply focus:border-#{$subject}-secondary
      border-#{$subject}-primary
      hover:border-#{$subject}-secondary;
    }
  }
}

@mixin deaflix-search-filter($subject-map) {
  @each $subject in $subject-map {
    &.deaflix-search-filter--#{$subject} {
      @apply border-#{$subject}-primary
      hover:border-#{$subject}-secondary;

      &.deaflix-search-filter--focused {
        @apply border-#{$subject}-secondary;
      }
    }
  }
}

@mixin deaflix-content-header-subject($subject-map) {
  @each $subject in $subject-map {
    &.deaflix-subject-header__content--#{$subject} {
      @apply after:bg-#{$subject}-primary
      before:bg-#{$subject}-primary
      shadow-#{$subject}-shadow;
    }
  }
}
