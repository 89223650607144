.grade-homepage {
  &__header {
    @apply col-span-2
    flex
    flex-col
    md:col-span-10
    md:col-start-2
    md:pt-16
    relative;

    &-image {
      @apply absolute
      h-[172px]
      hidden
      md:block
      right-0
      top-7
      w-auto;
    }

    &-background {
      //sass-lint:disable function-name-format
      @apply absolute
      bg-grade-homepage-header
      col-span-12
      hidden
      md:block
      rounded-subject-header
      w-[calc(100%-theme(width.sidebar)-theme(width.20))]
      max-w-[calc(theme(maxWidth.xl-container)-theme(width.20))]
      -z-[1];
      //sass-lint:enable function-name-format

      @media screen and (min-width: 1920px) {
        max-width: calc(theme(maxWidth.2xl-container) - theme(width.20));
      }
    }

    &-titles-container {
      @apply mb-5
      md:flex-1
      md:mb-3.5
      md:mx-0
      mx-auto;
    }

    &-title {
      @apply hidden
      mb-9
      md:block
      text-34
      text-black;

      &--strong {
        @apply font-dea-bold;
      }
    }

    &-subtitle {
      @apply flex
      flex-col
      font-dea-bold
      items-baseline
      leading-none
      md:flex-row
      md:text-2xl
      text-34
      text-black;

      svg {
        @apply h-4
        md:ms-5
        md:mt-0
        mt-3
        mx-auto
        w-4;

        g {
          rect {
            @apply fill-transparent;
          }

          g {
            @apply stroke-black;
          }
        }
      }
    }
  }

  &__pages-navigation-container {
    @apply col-span-2
    gap-y-2
    grid
    grid-cols-1
    lg:gap-x-[38px]
    md:col-span-10
    md:col-start-2
    md:gap-x-[33px]
    sm:gap-x-5
    sm:grid-cols-2
    xl:gap-x-[54px]
    xl:grid-cols-3;
  }

  &__pages-navigation-item {
    @apply flex;
  }

  &__page {
    @apply bg-white
    cursor-pointer
    flex
    flex-1
    items-center
    md:ps-3
    pe-5
    ps-[5px]
    py-[7px]
    rounded-subject-header
    shadow-subject-card
    sm:pe-2.5
    sm:ps-[5px]
    xs:ps-3;

    &:hover {
      .grade-homepage__page-icon-container {
        @apply sm:after:bg-blue;
      }
    }

    &-image {
      @apply h-auto
      md:me-5
      me-1
      sm:me-7
      w-[88px]
      xs:me-5;
    }

    &-titles-container {
      @apply flex-1;
    }

    &-title {
      @apply font-dea-bold
      text-sm
      xs:text-base;
    }

    &-subtitle {
      @apply font-dea-medium
      opacity-80
      text-black
      text-xs
      xs:text-sm;
    }

    &-icon-container {
      @apply sm:after:absolute
      sm:after:bg-transparent
      sm:after:h-3/4
      sm:after:right-0
      sm:after:rounded-sm
      sm:after:w-0.5
      sm:flex
      sm:h-full
      sm:items-center
      sm:pe-2.5
      sm:relative;
    }
  }
}
