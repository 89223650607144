@include subject-color($subject-map);

.deaflix-learning-object {
  @apply bg-white
  fixed
  h-screen
  left-0
  top-0
  w-screen
  z-[102];

  &__header {
    @apply flex
    flex-row
    justify-between
    px-[36px]
    py-[14px];

    &-cta {
      @apply grid
      place-items-center;

      &--flex {
        @apply flex
        gap-x-[15px]
        items-center;
      }

      &-icon {
        svg {
          height: auto !important; //sass-lint:disable-line no-important
          max-width: 64px;
          width: auto !important; //sass-lint:disable-line no-important
        }
      }
    }

    &-content {
      @apply flex
      flex-row
      gap-x-[15px];

      .dea-card-resource__card-header-spacer {
        @apply hidden;
      }
    }

    &-divider {
      @apply bg-white
      h-[21px]
      rounded-full
      w-px;
    }

    &-title {
      @apply font-dea-bold
      text-base
      text-white;
    }

    &-action {
      &--active {
        @apply relative after:bg-white after:rounded-active-action-border after:bottom-active-action-border after:content-[""] after:block after:h-active-action-border after:left-0 after:absolute after:w-full;
      }
    }

    .dea-tooltip__inner--top--right {
      @apply before:right-auto
      before:left-4
      after:left-[17px]
      after:right-auto;
    }

    .dea-tooltip__bookmark-saved {
      .dea-tooltip__inner {
        .dea-tooltip__inner--top--right {
          &:after {
            bottom: -$space-x-small + 1px;
            right: $space-small + 1px;
            transform: rotate(90deg);
          }

          &:before {
            bottom: -$space-x-small;
            right: $space-small;
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  &__content {
    @apply h-screen;
  }

  &-cards-list {
    @apply grid
    gap-y-2
    gap-x-4
    lg:gap-x-8
    lg:gap-y-6
    lg:grid-cols-4
    md:gap-x-8
    sm:gap-x-6.5
    sm:grid-cols-3
    xl:gap-x-9
    xl:grid-cols-5
    2xl:gap-x-[29px]
    2xl:grid-cols-6
    xs:gap-y-4
    xs:grid-cols-2
    2xs:grid-cols-1;
  }

  &-card {
    @apply col-span-1
    cursor-pointer
    min-h-lo-card-2xs
    p-5
    pb-5.5
    pt-6.5
    relative
    rounded-subject-header
    xs:min-h-lo-card;

    &:hover {
      .deaflix-learning-object-card__background {
        @apply before:right-[31.5px];
      }
    }

    &__background {
      @apply absolute
      after:absolute
      after:h-[438px]
      after:left-[71px]
      after:opacity-20
      after:rounded-full
      after:-bottom-[62px]
      xs:after:bottom-[49px]
      after:w-[438px]
      before:absolute
      before:h-[461px]
      before:opacity-30
      before:right-full
      before:rounded-full
      before:-bottom-[82.5px]
      before:w-[461px]
      overflow-hidden
      rounded-subject-header
      w-full
      h-full
      top-0
      left-0;
    }

    &__body {
      @apply flex
      flex-col
      h-full
      relative
      z-1;
    }

    &__header {
      @apply z-10;

      &-actions {
        @apply flex
        justify-end
        mb-6;
      }
    }

    &__content {
      @apply flex
      flex-col
      h-full
      justify-between
      z-1;
    }

    &__subject {
      @apply font-dea-light
      text-14
      text-white;
    }

    &__title {
      @apply font-dea-bold
      text-23
      text-white;
    }

    &__info-container {
      @apply flex
      flex-row
      items-end
      justify-between;

      .dea-card-resource__card-header--right {
        .dea-tooltip {
          @apply bottom-8
          top-auto
          right-0
          z-1;

          .dea-tooltip__inner--bottom {
            @apply after:right-[6px]
            before:right-1;
          }

          &.dea-tooltip__bookmark-saved {
            .dea-tooltip__inner--bottom {
              @apply before:border-b-0
              before:-bottom-[7px];
            }
          }
        }
      }
    }

    &__topic-text {
      @apply flex
      flex-col;
    }

    &__topic-label {
      @apply font-dea-light
      text-14
      text-white;
    }

    &__topic-title {
      @apply font-dea-bold
      leading-4.5
      text-14
      text-white;
    }

    .dea-card-resource__card-header-spacer {
      @apply hidden;
    }

    &--locked {
      &.deaflix-learning-object-card {
        @include subject-color-locked($subject-map);

        .deaflix-learning-object-card__background {
          @apply bg-none;
        }
      }
    }
  }
}
