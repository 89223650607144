.mobile-menu {
  @media screen and (min-width: 1281px) {
    display: none;
  }

  display: block;
  padding: 0 $space-base;
  position: relative;

  &--with-groups {
    @media screen and (min-width: 1281px) {
      display: none;
    }

    .mobile-menu__item,
    .mobile-menu__subitem {
      background-color: $dea-color-white;
      border: 0;
      display: flex;
      padding: 0;
      width: 100%;

      &-title {
        @include font-size($h-font-size-small);
        align-items: center;
        border-top: $border-light;
        display: flex;
        font-family: $font-primary-medium;
        line-height: $line-height-m;
        margin: 0;
        padding: $mobile-item-padding 0;
        width: 100%;

        .dea-links__icon {
          margin-left: auto;
          transition: transform 0.3s ease-in;
        }
      }
    }

    .mobile-menu__item {
      &:first-child {
        .mobile-menu__item-title {
          border-top: 0;
        }
      }

      &:last-child {
        padding-bottom: $space-base;

        .mobile-menu__item-title {
          border-bottom: $border-light;
        }
      }

      &-title {
        @include font-size($h-font-size-small);
        color: $dea-color-grey-dark;
        font-family: $font-primary-medium;
        line-height: $line-height-s;
      }
    }
  }
}
