$dea-color-target-green-deaflix: #c7d300;
$dea-color-white: #fff;
$dea-color-black: #000;
$dea-color-grey-dark: #333840;
$dea-color-grey-medium: #8e99ab;
$dea-color-grey-light: #ebeff5;
$dea-color-grey-medium-light: #c8d1e0;
$dea-color-pink: #e4015a;

$border-light-size: 1px;
$border-bold-size: 2px;
$border-bold-plus-size: 3px;
$border-light: solid $border-light-size;
$border-bold: solid $border-bold-size;
$border-bold-plus: solid $border-bold-plus-size;
$border-thick-size: 8px;
$border-thick: solid $border-thick-size;

$border-radius-small: 3px;
$border-radius-medium: 5px;

$deaflix-logo-height: 32px;
$dea-header-height: 50px; /* Base height of top header - desktop */
$dea-header-mobile-height: 67px; /* Base height of top header - mobile */
$dea-header-main-height: 67px; /* Base height of main header - desktop */
$dea-header-main-mobile-height: 70px; /* Base height of main header with in page menu - mobile */
$dea-mobile-menu-container-max-height: 88vh;
$dea-header-grey: #e5e5e5;
$dea-header-logo-height: 43px;

$sidebar-mobile-footer-height: 44px;

$mobile-item-margin: 22px;
$mobile-item-padding: 16px;

$space-base: 24px;
$space-small: 16px;
$space-xm-small: 12px;
$space-x-small: 8px;
$space-xs-small: 6px;
$space-xxx-small: 2px;

$h-font-size-mosaic-medium-text: 19px;
$h-font-size-medium: 18px;
$h-font-size-small: 16px;
$h-font-size-x-small: 14px;
$h-font-size-xx-small: 12px;

$line-height-m: 26px;
$line-height-s: 20px;

$font-primary-thin: "LarsseitThin", Helvetica, Arial, sans-serif;
$font-primary-light: "LarsseitLight", Helvetica, Arial, sans-serif;
$font-primary: "Larsseit", Helvetica, Arial, sans-serif;
$font-primary-medium: "LarsseitMedium", Helvetica, Arial, sans-serif;
$font-primary-bold: "LarsseitBold", Helvetica, Arial, sans-serif;
$font-primary-extra-bold: "LarsseitExtraBold", Helvetica, Arial, sans-serif;

$font-weight-bold: 700;
$font-weight-semi-bold: 600;
$font-weight-regular-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 300;

$opacity-focus: 0.9;

$dea-button-size-small: 32px;
