body {
  @apply overflow-x-hidden;
}

.container--with-sidebar {
  @apply flex
  flex-col
  md:flex-row
  relative
  w-full;

  .content-container {
    @apply 2xl:gap-x-2xl
    gap-x-2xs
    grid
    grid-cols-2
    lg:gap-x-lg
    md:flex-1
    md:gap-x-md
    md:grid-cols-12
    md:py-10
    px-10
    pb-10
    sm:gap-x-sm
    w-full
    xl:gap-x-xl;

    &-bg {
      @apply md:bg-subject-page w-full;
    }
  }
}

.content-container {
  @apply 2xl:gap-x-2xl-gap
  2xl:max-w-2xl-container
  gap-x-xs
  grid
  grid-cols-2
  lg:gap-x-xl
  lg:max-w-lg-container
  md:gap-x-md-gap
  md:grid-cols-12
  md:max-w-md-container
  md:min-w-0
  md:mx-auto
  md:px-0
  min-w-full
  px-10
  sm:gap-x-sm
  xl:max-w-xl-container;
}

.deaflix-grid {
  @extend .content-container;
  @apply py-12
  sm:py-16
  lg:py-24;

  > * + * {
    @apply mt-10
    sm:mt-14
    lg:mt-16;
  }

  .w-text {
    // This is an ugly workaround for Finalità Didattiche page: we should drop jessica4 layout in settings
    // and use tailwind layout, so that we can use layout options in plugins instead of this
    @apply col-span-2
    md:col-span-12;

    &__header {
      @apply mb-4;

      > * {
        @apply text-black
        text-24
        font-bold
        font-dea-regular
        md:text-30;
      }
    }

    &__text-body {
      > * {
        @apply text-black
        text-18
        font-dea-regular
        md:text-22-big;

        & + * {
          @apply mt-4
          sm:mt-6;
        }
      }

      li {
        @apply before:content-["◦"]
        before:mr-3
        before:text-24
        before:align-middle
        before:text-pink
        md:before:text-34;
      }
    }
  }
}

.homepage-header {
  @apply before:bg-homepage-header-colored
  before:opacity-50
  before:absolute
  before:inset-0
  lg:pt-32
  pt-12
  relative
  sm:pt-24
  z-0;

  &--white-gradient {
    @apply absolute
    bg-homepage-header-white
    inset-0;
  }

  &-cta-container,
  &-text-container,
  &-subtitle-container {
    @apply z-10;
  }

  &-cta-container {
    @apply col-span-2
    mb-7
    md:col-span-3
    md:order-none
    order-1
    sm:mb-16
    lg:mb-0;
  }

  &-text-container {
    @apply col-span-2
    lg:mb-11
    mb-7
    md:col-span-9
    md:order-1
    order-none
    sm:mb-9;
  }

  &-subtitle-container {
    @apply col-span-2
    md:col-span-9
    md:col-start-4
    order-2;
  }

  &-collage-container {
    @apply col-span-2
    flex
    justify-center
    mb-20
    sm:mb-24
    md:mb-32
    lg:mb-40
    md:col-span-12
    order-last
    z-10;
  }

  &-circle-container {
    @apply relative
    max-w-[280px]
    sm:max-w-[416px]
    md:max-w-[695px]
    lg:max-w-full;

    .phase-1-image,
    .phase-2-image,
    .phase-3-image {
      @apply absolute opacity-0;
    }

    .a-image {
      @apply top-0
      right-[-40px]
      sm:right-[-30px]
      md:right-[-60px]
      lg:right-[-75px]
      w-[140px]
      sm:w-[190px]
      md:w-[316px]
      lg:w-[360px]
      z-20;
    }

    .b-image {
      @apply top-[60px]
      sm:top-[100px]
      sm:left-[-40px]
      md:top-[155px]
      md:left-[-60px]
      lg:top-[180px]
      w-[270px]
      sm:w-[365px]
      md:w-[610px]
      lg:w-[690px]
      z-10;
    }

    .c-image {
      @apply w-[175px]
      sm:w-[236px]
      md:w-[390px]
      lg:w-[435px];

      &-first {
        @apply bottom-[-20px]
        right-[-25px]
        sm:bottom-[75px]
        sm:right-[-85px]
        md:bottom-[140px]
        md:right-[-150px]
        lg:bottom-[150px]
        lg:right-[-180px]
        z-30;
      }

      &-second {
        @apply bottom-[225px]
        right-[125px]
        sm:bottom-[40px]
        sm:right-[-130px]
        md:bottom-[80px]
        md:right-[-215px]
        lg:bottom-[85px]
        lg:right-[-260px]
        z-40;
      }
    }

    .d-image {
      &-first {
        @apply w-[115px]
        sm:w-[110px]
        md:w-[180px]
        lg:w-[205px]
        bottom-[4px]
        left-[-3px]
        sm:bottom-[45px]
        sm:left-[-120px]
        md:bottom-[85px]
        md:left-[-190px]
        lg:bottom-[90px]
        lg:left-[-210px]
        z-30;
      }

      &-second {
        @apply w-[56px]
        sm:w-[68px]
        md:w-[114px]
        lg:w-[128px]
        bottom-[33px]
        left-[-22px]
        sm:left-[-60px]
        sm:bottom-[80px]
        md:left-[-95px]
        md:bottom-[140px]
        lg:left-[-100px]
        lg:bottom-[155px]
        z-20;
      }

      &-third {
        @apply hidden
        sm:block
        sm:w-[73px]
        md:w-[122px]
        lg:w-[138px]
        sm:bottom-[30px]
        sm:left-[50px]
        md:bottom-[60px]
        md:left-[90px]
        lg:left-[110px]
        z-20;
      }

      &-fourth {
        @apply w-[56px]
        sm:w-[68px]
        md:w-[114px]
        lg:w-[128px]
        bottom-[30px]
        right-[-7px]
        sm:right-[155px]
        sm:bottom-[45px]
        md:right-[250px]
        md:bottom-[85px]
        lg:right-[270px]
        lg:bottom-[90px]
        z-20;
      }

      &-fifth {
        @apply hidden
        sm:block
        sm:w-[111px]
        md:w-[185px]
        lg:w-[208px]
        sm:bottom-[-20px]
        sm:right-[30px]
        md:right-[40px]
        lg:bottom-[-25px]
        lg:right-[30px]
        z-20;
      }
    }

    .e-image {
      @apply hidden
      sm:block;

      &-first {
        @apply sm:w-[231px]
        md:w-[384px]
        lg:w-[433px]
        sm:bottom-[145px]
        sm:right-[-90px]
        md:bottom-[253px]
        md:right-[-155px]
        lg:bottom-[275px]
        lg:right-[-188px]
        z-[9];
      }

      &-second {
        @apply sm:w-[110px]
        md:w-[182px]
        lg:w-[205px]
        sm:bottom-[115px]
        sm:right-[-120px]
        md:bottom-[205px]
        md:right-[-200px]
        lg:bottom-[220px]
        lg:right-[-255px]
        z-20;
      }
    }

    .f-image {
      @apply hidden
      sm:block
      sm:w-[73px]
      md:w-[122px]
      lg:w-[138px]
      sm:bottom-[175px]
      sm:left-[-138px]
      md:bottom-[300px]
      md:left-[-223px]
      lg:bottom-[330px]
      lg:left-[-245px]
      z-[9];
    }
  }

  &-title * {
    @apply font-bold
    sm:text-65
    text-45
    text-black
    font-dea-regular;
  }

  &-subtitle {
    @apply lg:mb-7
    mb-10
    sm:flex
    sm:items-end
    sm:mb-14;

    &-logo {
      @apply h-auto
      mb-3.5
      sm:mb-0
      w-homepage-header-logo;
    }

    &-text {
      @apply font-bold
      sm:ml-6
      text-20
      text-black
      font-dea-regular;
    }
  }
}

.slider {
  @apply relative;

  &-container {
    @extend .content-container;
  }

  &-title,
  &-image-container,
  &-text-container {
    @apply z-10;
  }

  &-title {
    @apply col-span-2
    md:col-span-12
    font-bold
    text-45
    text-black
    text-center
    mb-12
    md:mb-16
    font-dea-regular;
  }

  &-image {
    &-container {
      @apply hidden
      sm:flex
      sm:relative
      sm:col-span-1
      md:col-span-5
      xl:col-span-6
      2xl:col-span-5;

      svg {
        @apply w-full h-auto self-start;
      }
    }
  }

  &-text-container {
    @apply col-span-2
    sm:col-span-1
    md:col-span-5
    md:col-start-7
    mb-16
    md:mb-24
    lg:mb-36;

    .deaflix-content-button {
      @apply mx-auto
      my-16
      sm:ms-[89px]
      md:ms-[97px]
      lg:ms-[110px]
      2xl:ms-[144px]
      md:mt-12;
    }
  }
}

.slide {
  &-image {
    @apply right-[2000px]
    sm:absolute
    sm:max-w-none
    sm:w-[435px]
    sm-plus:w-[560px]
    md:w-[711px]
    lg:w-[745px]
    xl:w-auto
    xl:max-w-full
    sm-plus:-top-6
    md:-top-10
    lg:-top-20
    xl:-top-8
    sm:ease-in-out
    sm:transition-all
    sm:duration-300;

    &--active {
      @apply sm:right-14
      md:right-0;
    }
  }

  &-text {
    @apply flex
    cursor-pointer
    md:grid
    md:grid-cols-5
    md:gap-x-md-gap;

    &:hover {
      .slide-title {
        @apply text-pink;
      }
    }

    & + .slide-text {
      @apply mt-12;
    }

    &-box {
      @apply ms-8
      sm:ms-14
      md:ms-0
      md:col-span-4;
    }

    svg {
      @apply shrink-0
      self-center
      md:col-span-1
      md:mx-auto;
    }

    &--active {
      .slide-title {
        @apply sm:text-pink;
      }

      .slide-text-box {
        @apply sm:ms-[33px]
        md:ms-0;
      }

      svg {
        @apply sm:w-14
        sm:h-14
        md:mx-auto;
      }
    }
  }

  &-title {
    @apply font-bold
    text-23
    text-black
    font-dea-regular;
  }

  &-description {
    @apply text-18
    text-black
    font-dea-regular;
  }
}

.banner-image {
  @apply h-auto sm:h-680 bg-cover bg-center flex relative;

  &__overlay {
    @apply opacity-[0.39]
    bg-black
    absolute
    inset-0;
  }

  &__image {
    @apply block sm:hidden;
  }

  &__text {
    @apply absolute
    sm:relative
    w-full
    self-center
    px-6
    sm:px-24
    md:px-48;

    & > * {
      @apply text-center text-white;
    }

    h2 {
      @apply text-45 sm:text-65 mt-4 sm:mt-9 font-dea-regular;
    }

    h3 {
      @apply text-23 sm:text-45 font-dea-regular;
    }
  }
}

.text-button {
  @apply pb-16
  pt-20
  sm:pb-24
  sm:pt-32;

  &-container {
    @extend .content-container;
  }

  &-content,
  &-cta {
    @apply col-span-2
    md:col-span-12
    lg:col-span-10
    lg:col-start-2;
  }

  &-content {
    @apply text-center;

    * {
      @apply text-30
      sm:text-34
      text-black
      font-dea-regular;
    }

    strong span {
      @apply font-bold;
    }
  }

  &-cta {
    @apply flex
    justify-center
    mt-12
    sm:mt-16;
  }
}

.fixed-slider {
  @apply pb-16;

  &-container {
    @extend .content-container;
  }

  &-title,
  &-nav,
  .fixed-slide {
    @apply col-span-2;
  }

  &-title,
  &-nav {
    @apply md:col-span-12;
  }

  &-title {
    @apply text-45
    text-black
    font-bold
    text-center
    mb-9
    font-dea-regular;
  }

  &-nav {
    @apply hidden
    sm:flex
    list-none
    justify-center;

    &-item {
      @apply text-16
      font-bold
      text-black-70
      cursor-pointer
      hover:text-pink
      flex
      flex-col
      items-center
      font-dea-regular;

      & + .fixed-slider-nav-item {
        @apply ml-9;
      }

      &--active {
        @apply text-pink;

        .fixed-slider-nav-item--border {
          @apply bg-pink;
        }
      }

      &--border {
        @apply w-7
        h-[3px]
        bg-pink-20
        mt-1
        rounded-s-sm;
      }

      &:hover {
        .fixed-slider-nav-item--border {
          @apply bg-pink;
        }
      }
    }
  }

  &-slides-container {
    @apply relative
    col-span-2
    md:col-span-10
    md:col-start-2
    md:min-h-fixed-slider-image;
  }

  .fixed-slide {
    @apply w-full
    md:absolute
    md:inset-0;

    &-container {
      @apply relative;
    }

    &-title {
      @apply flex
      flex-col
      items-center
      sm:hidden
      font-bold
      text-pink
      text-16
      font-dea-regular;

      &--border {
        @apply w-7
        h-[3px]
        bg-pink
        mt-1
        rounded-s-sm;
      }
    }

    &-image {
      @apply rounded-subject-header
      w-full
      mt-8
      mb-9
      md:mt-11
      md:w-4/5
      sm:h-fixed-slider-image
      sm:object-cover;

      &-container {
        @apply relative;
      }

      &-overlay {
        @apply hidden
        sm:block
        sm:absolute
        sm:inset-0
        sm:rounded-subject-header
        sm:bg-black
        opacity-60
        md:w-4/5;
      }
    }

    &-text-over-image {
      @apply sm:absolute
      sm:bottom-0
      sm:max-w-1/2
      sm:left-16
      lg:bottom-14;

      &__uptitle {
        @apply hidden
        sm:block
        sm:text-white
        sm:text-16
        sm:mb-1
        sm:uppercase
        font-dea-regular;
      }

      &__title {
        @apply text-45
        text-black
        font-bold
        mb-6
        sm:text-white
        sm:mb-10
        font-dea-regular;
      }
    }

    &-content {
      @apply md:absolute
      md:top-9
      md:right-0
      md:w-1/2
      md:bg-white
      md:px-20
      md:py-16
      md:rounded-fixed-slide
      md:shadow-subject-card
      lg:px-28
      lg:py-16;

      &__title {
        @apply hidden
        md:block
        md:text-pink
        md:font-medium
        md:text-14
        md:mb-5
        font-dea-regular;
      }

      &__description {
        @apply mb-5;

        > * {
          @apply text-24
          text-black
          font-dea-regular;

          & + * {
            @apply mt-3.5;
          }
        }
      }
    }

    & + .fixed-slide {
      @apply mt-14 sm:mt-0;
    }
  }
}

.grades {
  @apply mt-40
  md:mt-56
  relative
  block;

  &--sticky {
    @apply sm:sticky
    sm:top-56
    md:top-72
    lg:top-80;
  }

  &-background {
    @apply before:bg-homepage-grades
    before:opacity-[65%]
    before:absolute
    before:inset-0
    before:z-[-1]
    relative
    md:col-span-10
    md:col-start-2
    md:before:rounded-subject-header
    pt-14
    pb-20
    md:py-20;

    &:hover {
      .grades-uptitle,
      .grades-title {
        @apply text-pink;
      }

      .deaflix-content-button {
        @apply bg-pink;
      }
    }

    &-container {
      @apply 2xl:gap-x-2xl-gap
      2xl:max-w-2xl-container
      grid
      lg:gap-x-xl
      lg:max-w-lg-container
      md:gap-x-md-gap
      md:grid-cols-12
      md:max-w-md-container
      md:min-w-0
      md:mx-auto
      md:px-0
      xl:max-w-xl-container;
    }
  }

  &-image {
    @apply h-homepage-subject-logo-mobile
    md:h-homepage-subject-logo-desktop
    absolute
    left-1/2
    top-[-35%]
    md:top-[-50%]
    translate-x-[-50%];
  }

  &-text-container {
    @extend .content-container;
    @apply px-1;
  }

  &-uptitle {
    @apply col-span-2
    md:col-span-12
    text-black
    text-22
    text-center
    font-dea-regular
    px-7
    md:px-0;
  }

  &-title {
    @apply col-span-2
    md:col-span-12
    text-black
    text-45
    font-bold
    text-center
    mt-2
    mb-6
    font-dea-regular;
  }

  .deaflix-content-button {
    @apply col-span-2
    md:col-span-12
    mx-auto;
  }

  &-subjects {
    @apply flex
    bottom-[36px]
    py-2.5
    relative
    overflow-x-hidden;

    .grade-homepage__pages-navigation-item {
      @apply w-homepage-subject-card
      min-w-homepage-subject-card
      h-homepage-subject-card
      px-1;

      .grade-homepage__page {
        @apply rounded-homepage-subjects
        shadow-homepage-subject-card
        cursor-auto;

        &-image {
          @apply w-homepage-subject-card-image
          mr-2.5;
        }

        &-title {
          @apply text-12
          font-dea-regular;
        }

        &-subtitle {
          @apply text-8
          font-dea-regular;
        }
      }
    }
  }
}

.more-info {
  @apply mb-28
  relative
  bg-white;

  &-top {
    @extend .content-container;

    &-container {
      @apply relative
      bg-black
      rounded-more-info
      mt-8
      pt-44
      pb-650
      sm:pb-350;
    }
  }

  &--no-margin-top {
    .more-info-top-container {
      @apply mt-0
      rounded-none;
    }
  }

  &-second-row,
  &-third-row {
    @apply grid
    col-span-2
    md:col-span-12;

    grid-template-columns: subgrid;
  }

  &-divider {
    @apply col-span-2
    md:col-span-10
    md:col-start-2
    border-b
    border-lighter-gray
    my-16
    sm:mt-32
    sm:mb-24;
  }

  &-title {
    @apply col-span-2
    sm:col-span-1
    md:col-span-4
    md:col-start-2
    lg:col-span-3
    lg:col-start-2
    text-white
    font-bold
    text-45
    font-dea-regular;
  }

  &-second-title,
  &-third-title {
    @apply col-span-2
    sm:col-span-1
    md:col-span-4
    md:col-start-2
    lg:col-span-3
    lg:col-start-2
    text-white
    font-bold
    text-30
    mb-10
    sm:mb-0
    font-dea-regular;
  }

  &-description {
    @apply col-span-2
    sm:col-span-1
    md:col-span-7
    md:col-start-6
    text-white
    text-34
    font-dea-regular
    mt-6
    sm:mt-4;
  }

  &-second-description,
  &-third-description {
    @apply col-span-2
    sm:col-span-1
    md:col-span-7
    md:col-start-6;

    & * {
      @apply text-white
      text-22
      font-dea-regular;
    }
  }

  &-contact {
    @apply col-span-2
    sm:col-span-1
    sm:col-start-2
    md:col-span-7
    md:col-start-6
    mt-14
    md:mt-11;
  }

  &-second-cta,
  &-third-cta {
    @apply col-span-2
    sm:col-span-1
    sm:col-start-2
    md:col-span-7
    md:col-start-6
    mt-12
    md:mt-8;
  }

  &-email {
    @apply text-green
    font-bold
    text-24
    pb-2
    border-b
    border-green
    mb-6
    block
    w-fit
    hover:text-pink
    hover:border-pink
    font-dea-regular;
  }

  &-boxes {
    @extend .content-container;

    @apply absolute
    -bottom-44
    sm:-bottom-48
    md:left-0
    md:right-0;
  }

  &-box {
    @apply col-span-2
    sm:col-span-1
    md:col-span-4
    relative
    flex
    flex-col
    pt-10
    pr-10
    pl-7
    pb-14
    md:pl-14
    rounded-more-info-card
    bg-cover
    before:bg-black
    before:opacity-70
    before:absolute
    before:inset-0
    before:rounded-more-info-card
    first:md:col-span-8;

    & + .more-info-box {
      @apply mt-6
      sm:mt-0;
    }

    &-text {
      @apply relative
      mt-auto;
    }

    &-button {
      @apply rounded-subject-header
      bg-green
      p-4
      block
      w-12
      h-12
      rotate-45
      ml-auto
      group-hover/info:bg-pink;

      svg {
        @apply -rotate-45
        w-[16px]
        h-[19px]
        -mt-0.5;
      }
    }

    &-uptitle {
      @apply text-white
      text-16
      uppercase
      mb-2.5
      mt-28
      font-dea-regular;
    }

    &-title {
      @apply text-white
      font-bold
      text-34
      font-dea-regular;
    }
  }

  &-faq-container {
    @apply mt-60;
  }

  &-faq {
    @extend .content-container;

    @apply md:flex
    md:items-center
    md:gap-x-0;

    &-title {
      @apply col-span-2
      text-black
      text-45
      font-bold
      mb-2
      md:mb-0
      font-dea-regular;
    }

    &-subtitle {
      @apply col-span-2
      sm:col-span-1
      text-black
      text-24
      font-bold
      mb-6
      md:mb-0
      md:ml-6
      font-dea-regular;
    }

    &-description {
      @apply col-span-2
      sm:col-span-1
      text-black
      text-18
      font-dea-regular
      mb-6
      md:mb-0
      md:ml-4;
    }

    .deaflix-content-button {
      @apply col-span-2
      sm:col-span-1
      sm:col-start-2
      md:ml-auto;
    }
  }
}

.text-header {
  @apply lg:pt-32
  lg:pb-24
  pt-12
  pb-8
  sm:pt-24
  sm:pb-16
  bg-white;

  &-top-container,
  &-columns-container {
    @extend .content-container;
  }

  &-columns-container {
    @apply md:gap-y-10
    sm:gap-y-8
    gap-y-6;
  }

  &-top {
    @apply col-span-2
    sm:col-span-8
    md:col-span-8
    md:col-start-3
    mb-14
    sm:mb-24;

    .deaflix-content-button {
      @apply sm:py-5
      sm:px-14
      px-7
      py-2.5;

      &__text {
        @apply font-bold
        sm:text-18
        text-14
        font-dea-regular;
      }
    }
  }

  &-uptitle {
    @apply font-bold
    text-22-big
    text-black
    mb-5
    font-dea-regular;
  }

  &-title * {
    @apply font-bold
    sm:text-65
    text-45
    text-black
    mb-10
    font-dea-regular;
  }

  &-left-column {
    @apply col-span-2
    sm:col-span-1
    md:col-span-4
    md:col-start-2;

    &-title {
      @apply text-34
      font-bold
      text-black
      font-dea-regular;
    }
  }

  &-right-column {
    @apply col-span-2
    sm:col-span-1
    md:col-span-5
    md:col-start-7;

    &-description > * {
      @apply text-24
      text-black
      font-dea-regular;
    }
  }
}

.fixed-anchors-header {
  @extend .content-container;

  @apply sm:grid hidden;

  &-title {
    @apply col-span-2
    md:col-span-12
    mx-auto
    mb-20
    font-bold
    text-45
    text-black
    font-dea-regular;
  }

  &-anchor-container {
    @apply col-span-2
    md:col-span-12
    mx-auto
    flex
    items-center
    gap-x-11
    pb-7
    mb-20
    border-b
    border-b-pink
    relative
    after:block
    after:absolute
    after:w-3
    after:h-3
    after:border-t
    after:border-r
    after:rotate-45
    after:right-0
    after:-bottom-1.5
    after:border-pink;
  }

  &-anchor {
    @apply flex;

    .fixed-anchor-container-navigation-item-text {
      @apply text-black
      font-dea-regular;
    }

    &--active {
      .fixed-anchor-container-navigation-item-text {
        @apply text-pink
        after:bg-pink;
      }
    }
  }
}

.fixed-anchor-container {
  @apply pb-10
  pt-16
  sm:pb-16
  sm:pt-8
  md:pb-12
  md:pt-8
  lg:pt-12;

  &--with-header {
    @apply relative
    lg:pt-12;

    .fixed-anchor-container-grid {
      @apply before:bg-fixed-anchors
      before:opacity-60
      before:absolute
      before:inset-0;
    }
  }

  &-grid {
    @extend .content-container;
  }

  &-sidebar {
    @apply hidden
    sm:block
    col-span-2
    sm:col-span-1
    md:col-span-4
    md:pb-24
    relative
    sm:pt-16
    md:pt-24;
  }

  &-content {
    @apply grid
    col-span-2
    sm:col-span-1
    md:col-span-8
    relative;

    grid-template-columns: subgrid;
  }

  &-navigation {
    @apply sm:sticky
    sm:top-24
    lg:top-40;

    &-item {
      @apply flex
      items-center;

      svg g g {
        @apply stroke-black;
      }

      &--active {
        .fixed-anchor-container-navigation-item-text {
          @apply text-pink
          after:bg-pink;
        }

        svg g g {
          @apply stroke-pink;
        }
      }

      &-text {
        @apply text-16
        text-black-70
        font-bold
        ms-6
        font-dea-regular
        after:w-7
        after:h-[3px]
        after:bg-pink-20
        after:block
        after:mt-1.5
        after:rounded-sm;
      }

      & + .fixed-anchor-container-navigation-item {
        @apply mt-6;
      }
    }
  }

  .fixed-anchor {
    @apply grid
    col-span-2
    sm:col-span-1
    md:col-span-8
    md:pb-24
    md:border-b-2
    md:border-pink-20
    md:last:border-0
    sm:pt-16
    md:pt-24;

    grid-template-columns: subgrid;

    &--active {
      .fixed-anchor-header-icon {
        @apply after:bg-pink;

        svg g g {
          @apply stroke-pink;
        }
      }
    }

    & + .fixed-anchor {
      @apply mt-16
      sm:mt-0;
    }

    &-header {
      @apply flex
      items-start
      mb-8
      md:mb-7
      col-span-8;

      &-icon {
        @apply hidden
        md:flex
        flex-col
        after:w-4
        after:h-0.5
        after:bg-black
        after:block
        after:mt-1
        after:rounded-sm
        after:mx-auto;
      }

      svg g g {
        @apply stroke-black;
      }

      &-title {
        @apply text-34
        font-bold
        text-pink
        sm:text-black
        md:ms-7
        font-dea-regular;
      }
    }

    &-content {
      @apply col-span-2
      sm:col-span-7
      sm:col-start-2;

      * {
        @apply text-18
        font-dea-regular;
      }

      .pink-text {
        @apply font-medium
        font-dea-regular;
      }
    }
  }
}

.text-two-buttons {
  @apply pt-16
  pb-12
  sm:pt-28
  sm:pb-14
  md:pt-36
  md:pb-16;

  &-container {
    @extend .content-container;
  }

  &-title,
  &-description,
  &-buttons-container {
    @apply col-span-2
    md:col-span-6
    md:col-start-4
    text-center;
  }

  &-title {
    @apply text-45
    font-bold
    text-black
    mb-7
    font-dea-regular;
  }

  &-description {
    @apply mb-9;

    & > * {
      @apply text-18
      text-black
      font-dea-regular;

      & + * {
        @apply mt-12;
      }
    }

    .pink-text {
      @apply font-medium
      font-dea-regular;
    }
  }

  &-buttons-container {
    @apply flex
    flex-col
    sm:flex-row
    justify-center
    items-center;

    .deaflix-content-button + .deaflix-content-button {
      @apply mt-6
      sm:ml-9
      sm:mt-0;
    }
  }
}

.economic-offer-container {
  @apply pt-7
  md:sticky;

  &-background {
    @apply bg-economic-offer
    absolute
    top-0
    bottom-0
    left-0
    right-0
    z-[-1]
    opacity-60;
  }

  &-title {
    @apply font-bold
    text-14
    text-black
    text-center
    mb-2.5
    font-dea-regular;
  }
}

.economic-offer {
  & + .economic-offer {
    @apply mt-16;
  }

  &__container {
    @extend .content-container;
  }

  &__description {
    @apply col-span-2
    md:col-span-12
    before:w-6
    before:h-[3px]
    before:bg-pink
    before:block
    before:rounded-sm
    before:opacity-70
    before:mx-auto
    before:mb-8;

    & > * {
      @apply text-center
      font-medium
      text-22
      text-pink
      font-dea-regular;
    }
  }

  &__years {
    @apply flex
    gap-x-1
    sm:gap-x-2.5
    w-full
    justify-center
    mt-1
    sm:my-10
    bg-pink
    sm:w-fit
    mx-auto
    sm:rounded-economic-offer-grades
    border
    border-pink;

    &-container {
      @apply pt-0.5
      pb-1
      px-7
      xs:px-10
      my-10
      sm:my-0
      sm:p-0
      bg-pink
      sm:bg-transparent
      md:relative
      top-0
      transition-[top]
      duration-300;

      &.is-sticky {
        @apply bg-pink
        sticky
        top-0
        pt-0.5
        pb-1
        px-7
        xs:px-10;

        .economic-offer__years {
          @apply mb-0
          mt-1
          w-full;
        }

        .economic-offer__years-grade {
          @apply flex
          pb-1
          border-b
          border-b-white-60;
        }

        .economic-offer__year-button {
          @apply py-1
          w-full;

          &-label {
            @apply text-14
            font-dea-regular;
          }

          &-discount {
            @apply text-12
            font-dea-regular;
          }
        }
      }
    }

    &-grade {
      @apply sm:hidden
      justify-center
      mt-2
      text-white
      uppercase
      font-medium
      text-center
      text-10
      pb-1
      border-b
      border-b-white-60
      font-dea-regular;
    }
  }

  &__year-button {
    @apply py-1
    sm:py-3.5
    w-full
    sm:w-auto
    sm:min-w-economic-offer-button
    rounded-economic-offer-grades
    flex
    items-center
    justify-center
    hover:bg-white;

    &.active {
      @apply bg-white;

      .economic-offer__year-button-label,
      .economic-offer__year-button-discount {
        @apply text-pink;
      }
    }

    &-label {
      @apply font-bold
      text-14
      sm:text-18
      text-white
      group-hover:text-pink
      font-dea-regular;
    }

    &-discount {
      @apply font-medium
      text-12
      sm:text-14
      text-white
      ml-1.5
      group-hover:text-pink
      font-dea-regular;
    }
  }

  &__items-for-year {
    @apply hidden
    col-span-2
    md:col-span-12;

    &.active {
      @apply grid;
    }

    grid-template-columns: subgrid;
  }

  &__item {
    @apply col-span-2
    max-w-economic-offer-item
    mx-auto
    md:max-w-full
    md:col-span-4
    bg-white
    shadow-economic-offer-card
    rounded-subject-header;

    & + .economic-offer__item {
      @apply mt-12
      md:mt-0;
    }

    &-content {
      @apply flex
      flex-col;
    }

    &-year-label {
      @apply font-bold
      text-14
      text-pink
      mt-3
      pb-0.5
      border-b
      border-pink
      w-fit
      mx-auto
      font-dea-regular;
    }

    &-image {
      @apply md:px-16;

      img {
        @apply mx-auto
        max-w-4/5
        sm:max-w-full;
      }
    }

    &-characteristics {
      @apply font-bold
      text-23
      text-black
      text-center
      font-dea-regular;
    }

    &-price {
      @apply mt-3.5
      text-center
      flex
      flex-col
      md:flex-row
      justify-center
      items-center;

      &-value {
        @apply font-bold
        text-45
        text-black
        font-dea-regular;
      }

      &-discount {
        @apply font-bold
        text-27
        text-lighter-gray
        line-through
        mr-0
        md:mr-6
        mb-2.5
        md:mb-0
        font-dea-regular;
      }

      &-vat {
        @apply text-14
        text-black
        text-center
        mt-1.5
        font-dea-regular;
      }
    }

    &-mepa {
      @apply font-medium
      text-14
      text-black
      text-center
      mb-4
      md:mb-8
      font-dea-regular;
    }

    &-divider {
      @apply mx-16
      border-black
      border-b
      mt-5
      mb-3;
    }
  }

  &__financing {
    @apply mt-16
    md:mt-24
    mb-32;

    &-content {
      @apply col-span-2
      md:col-span-10
      md:col-start-2
      flex
      flex-col
      md:flex-row
      items-center;
    }

    &-image {
      @apply w-[87px]
      h-[87px]
      mb-6
      md:mb-0;
    }

    &-title {
      @apply font-bold
      text-30
      text-black
      ml-11
      mr-16
      mb-6
      md:mb-0
      font-dea-regular;
    }

    &-text > * {
      @apply text-18
      text-black
      text-center
      md:text-left
      font-dea-regular;
    }
  }
}

.banner-image-with-boxes {
  @apply sm:h-680
  bg-cover
  bg-center
  flex
  relative
  mt-12
  mb-16
  sm:my-32;

  &__overlay {
    @apply opacity-[0.53]
    bg-black
    absolute
    inset-0
    hidden
    sm:block;
  }

  &__boxes {
    @extend .content-container;

    @apply relative;
  }

  &__box {
    @apply col-span-2
    sm:col-span-1
    md:col-span-3
    px-10
    py-14
    rounded-banner-image-boxes
    bg-black
    h-min;

    &-uptitle,
    &-description {
      @apply text-white
      font-dea-regular;
    }

    &-uptitle {
      @apply text-14
      mb-5;
    }

    &-description {
      @apply text-24;
    }

    & + .banner-image-with-boxes__box {
      @apply mt-5
      sm:mt-0;
    }
  }
}

.text-two-columns {
  @apply pt-8
  mb-16
  sm:pt-16
  sm:mb-24;

  &__container {
    @extend .content-container;

    @apply gap-y-8
    sm:gap-y-16;
  }

  &__first-column-top,
  &__first-column-bottom {
    @apply col-span-2
    sm:col-span-1
    md:col-span-5
    md:col-start-2;
  }

  &__second-column-top,
  &__second-column-bottom {
    @apply col-span-2
    sm:col-span-1
    md:col-span-5
    md:col-start-7;
  }

  &__first-column-top-title {
    @apply font-bold
    text-black
    text-34
    font-dea-regular;
  }

  &__second-column-top * {
    @apply text-black
    text-24
    font-dea-regular;
  }

  &__first-column-bottom *,
  &__second-column-bottom * {
    @apply text-black
    text-18
    font-dea-regular;

    strong span {
      @apply font-bold;
    }
  }
}

.image-no-background {
  @apply mb-16;

  &__container {
    @extend .content-container;
  }

  &__image {
    @apply col-span-2
    md:col-span-12;
  }
}

.text {
  @apply mt-32
  mb-24;

  &--sticky {
    @apply md:sticky
    md:top-44;
  }

  &-container {
    @extend .content-container;
  }

  &-content {
    @apply col-span-2
    md:col-span-10
    md:col-start-2;

    * {
      @apply text-34
      text-black
      font-dea-regular;
    }

    .pink-text {
      @apply font-medium
      font-dea-regular;
    }
  }
}
