.deaflix-sidebar-button {
  @apply md:before:absolute
  md:before:bg-transparent
  md:before:h-10
  md:before:left-0
  md:before:rounded-sm
  md:before:transition-colors
  md:before:w-1.5
  md:px-sidebar-padding;

  & + .deaflix-sidebar-button {
    @apply md:ml-0
    md:mt-10
    ml-8;
  }

  &:hover,
  &--active {
    .deaflix-sidebar-button__link-image {
      @apply rotate-45;

      svg {
        @apply -rotate-45;
      }
    }
  }

  &--active {
    @apply md:before:bg-pink;

    .deaflix-sidebar-button__link-image {
      @apply md:after:-rotate-45
      md:after:bg-pink;
    }

    .deaflix-sidebar-button__link-text {
      @apply after:-bottom-0.5
      after:-translate-x-1/2
      after:absolute
      after:bg-pink
      after:h-px
      after:left-1/2
      after:rounded-sm
      after:w-3
      md:after:content-none;
    }
  }

  &__link {
    @apply flex
    flex-col
    items-center
    md:flex-row;

    &-image {
      @apply bg-pink
      grid
      h-[34px]
      md:after:-bottom-0.5
      md:after:absolute
      md:after:bg-transparent
      md:after:h-px
      md:after:left-[34px]
      md:after:rounded-sm
      md:after:w-4
      md:h-10
      md:w-10
      place-items-center
      rounded-sidebar-icons
      transition-transform
      w-[34px];

      svg {
        @apply bg-pink
        h-4
        md:h-5
        md:w-5
        transition-transform
        w-4;

        rect {
          @apply fill-pink;
        }
      }
    }

    &-text {
      @apply font-dea-bold
      md:font-dea-extra-bold
      md:ml-5
      md:mt-0
      md:text-base
      md:font-bold
      md:text-black
      mt-2
      relative
      text-10
      text-pink;
    }
  }
}
