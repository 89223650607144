.deaflix-button {
  @apply flex
  gap-2
  h-6
  items-center;

  &--white-text {
    @apply text-white;

    svg {
      @apply fill-white;
    }
  }

  &--rounded {
    @apply rounded-full;
  }

  &--squared {
    @apply aspect-square;
  }

  &--white {
    @apply bg-white
    text-black;
  }

  &--primary {
    @apply p-2;

    * {
      @apply h-5;
    }
  }

  &--centered {
    @apply grid
    place-items-center;
  }

  &--share {
    @apply h-6
    text-xs;

    img {
      @apply h-5;
    }
  }

  &--icon {
    @apply h-6;

    img {
      @apply h-2.5;
    }
  }

  &--xs,
  &--sm {
    @apply text-10;

    img {
      @apply w-2.5;
    }
  }

  &--xs {
    @apply h-4;
  }

  &--sm {
    @apply h-6;
  }

  &--md,
  &--lg {
    @apply text-sm;

    img {
      @apply w-3.5;
    }
  }

  &--md {
    @apply h-9;
  }

  &--lg {
    @apply h-11;
  }

  &--xl {
    @apply h-[62px]
    text-lg;

    img {
      @apply w-4;
    }
  }

  .deaflix-icon-share {
    svg {
      @apply fill-white;
    }
  }
}

.deaflix-content-button {
  @apply flex
  items-center
  justify-between
  w-fit;

  &--big {
    @apply px-7
    py-2.5
    rounded-small-content-buttons
    sm:font-bold
    sm:min-w-big-button
    sm:pe-10
    sm:ps-14
    sm:py-5
    sm:rounded-big-content-buttons;

    span {
      @apply font-dea-regular
      font-medium
      sm:font-bold
      sm:text-18
      text-14;
    }

    &:not(.deaflix-content-button--with-icon) {
      @apply justify-center sm:px-14 sm:min-w-0;
    }
  }

  &--small {
    @apply px-7
    py-2.5
    rounded-small-content-buttons;

    span {
      @apply font-dea-regular
      font-medium
      text-14;
    }

    .deaflix-content-button__icon {
      @apply ps-6;

      svg {
        @apply w-2.5
        h-3;
      }
    }
  }

  &--pink span,
  &--grey span {
    @apply text-white;
  }

  &--green {
    @apply bg-green
    hover:bg-pink;

    span {
      @apply text-black;
    }

    &:hover span {
      @apply text-white;
    }
  }

  &--pink {
    @apply bg-pink;

    &:not(.deaflix-content-button--with-icon) {
      @apply hover:bg-black;
    }
  }

  &--grey {
    @apply bg-black;

    &.deaflix-content-button--small {
      @apply hover:bg-pink;
    }
  }

  &--white {
    @apply bg-white
    text-black;

    svg path {
      @apply fill-black;
    }
  }

  &--with-icon:hover {
    .deaflix-content-button__icon {
      @apply animate-button;
    }
  }

  &__icon {
    @apply ps-6
    sm:ps-0;

    svg {
      @apply h-3
      h-4
      rotate-90
      sm:w-3.5
      w-2.5;

      path {
        @apply fill-white;
      }
    }
  }
}
