// sass-lint:disable indentation
$base-url: "..";

@font-face {
  font-family: "LatoThinItalic";
  font-style: normal;
  font-weight: normal;
  src: url("#{$base-url}/assets/fonts/Lato-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: "LatoThin";
  font-style: normal;
  font-weight: normal;
  src: url("#{$base-url}/assets/fonts/Lato-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "LatoRegular";
  font-style: normal;
  font-weight: normal;
  src: url("#{$base-url}/assets/fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "LatoLightItalic";
  font-style: normal;
  font-weight: normal;
  src: url("#{$base-url}/assets/fonts/Lato-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "LatoLight";
  font-style: normal;
  font-weight: normal;
  src: url("#{$base-url}/assets/fonts/Lato-Light.ttf") format("truetype");
}

@font-face {
  font-family: "LatoItalic";
  font-style: normal;
  font-weight: normal;
  src: url("#{$base-url}/assets/fonts/Lato-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "LatoBoldItalic";
  font-style: normal;
  font-weight: normal;
  src: url("#{$base-url}/assets/fonts/Lato-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "LatoBold";
  font-style: normal;
  font-weight: normal;
  src: url("#{$base-url}/assets/fonts/Lato-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "LatoBlackItalic";
  font-style: normal;
  font-weight: normal;
  src: url("#{$base-url}/assets/fonts/Lato-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "LatoBlack";
  font-style: normal;
  font-weight: normal;
  src: url("#{$base-url}/assets/fonts/Lato-Black.ttf") format("truetype");
}

@font-face {
  font-family: "deascuola";
  font-style: normal;
  font-weight: normal;
  src: url("#{$base-url}/assets/fonts/deascuola.eot?#iefix") format("embedded-opentype"),
    url("#{$base-url}/assets/fonts/deascuola.woff") format("woff"),
    url("#{$base-url}/assets/fonts/deascuola.ttf") format("truetype"),
    url("#{$base-url}/assets/fonts/deascuola.svg#deascuola") format("svg");
}
// sass-lint:enable indentation
