.project-page {
  &__main-content {
    &-buttons {
      @apply w-full flex items-center justify-between;
    }
  }

  .dea-card-resource__share-dropdown {
    @apply left-8;
  }
}

.deaflix-select-filter {
  @apply cursor-pointer
  flex
  flex-row
  justify-start
  w-full
  md:justify-end
  md:w-auto;

  &--hidden {
    @apply hidden
    md:block;
  }

  &__dropdown {
    @apply flex
    flex-row
    relative;
  }

  &__button {
    @include deaflix-search-filter-button($subject-map);
    @apply border-b-0
    flex
    font-dea-regular
    items-center
    p-0
    text-sm
    md:border-b-[1px]
    md:p-2;

    &-option-type {
      @apply font-dea-regular
      hidden
      items-center
      text-sm
      md:block;
    }

    .svg-icon {
      @apply bg-black
      h-4
      rounded-full
      w-4;

      svg {
        @apply fill-white;
      }
    }

    &-divider {
      @apply border-black
      border-r-[1px]
      h-5
      hidden
      mx-3
      md:block;
    }

    &-value {
      @apply font-dea-bold
      hidden
      text-sm
      md:block;

      .deaflix-icon {
        @apply ml-3;
      }
    }

    &-mobile-value {
      @apply font-dea-bold
      ml-[34px]
      text-base
      text-white
      md:hidden;
    }

    &-icon {
      @apply bg-black
      h-4
      hidden
      ml-3
      rounded-full
      w-4
      md:block;
    }

    & .svg-icon.deaflix-select-filter__button-mobile-icon.deaflix-icon.deaflix-icon-filter {
      @apply bg-transparent
      ml-0
      rounded-none
      md:hidden;
    }

    .deaflix-icon {
      @apply ml-3;
    }

    &--default {
      @apply active:border-default-secondary
      border-default-primary
      hover:border-default-secondary;
    }
  }

  &__list {
    @apply border-none
    min-w-[316px]
    overflow-auto
    px-3
    py-4
    right-0
    shadow-subject-card
    z-50
    md:absolute
    md:bg-white
    md:h-fit
    md:mt-3
    md:rounded-xl
    md:top-9
    md:w-auto;

    &-mobile {
      @apply max-md:bg-black
      max-md:border-none
      max-md:fixed
      max-md:h-[calc(100vh-66px)]
      max-md:inset-x-0
      max-md:mt-0
      max-md:overflow-auto
      max-md:px-10
      max-md:py-6
      max-md:rounded-none
      max-md:shadow-subject-card
      max-md:top-0
      max-md:w-full
      max-md:z-50;

      > div {
        @apply max-md:border-solid
        max-md:border-b
        max-md:border-white
        max-md:pb-4;
      }

      > ul {
        @apply max-md:h-[calc(100%-24px)]
        max-md:mt-[30px]
        max-md:p-0
        max-md:shadow-none;

        > li {
          @apply max-md:flex-row-reverse;

          > .deaflix-select-filter__option-bullet {
            @apply max-md:bg-white
            max-md:mr-11
            max-md:mx-0;
          }
        }
      }
    }

    &-header {
      @apply flex
      flex-row
      items-center
      justify-between
      md:hidden;

      &-label {
        @apply flex
        flex-row
        items-center
        text-white;

        &-icon {
          @apply mr-[34px];
        }
      }

      &-close {
        @apply bg-white
        h-[24px]
        rounded-full
        w-[24px];
      }
    }
  }

  &__option {
    @apply flex
    flex-row
    font-dea-light
    items-center
    justify-end
    leading-8
    my-2
    text-end
    text-sm
    md:leading-normal;

    &--selected {
      @apply mr-0;
    }

    &-bullet {
      @apply h-2
      invisible
      ml-4
      min-w-[8px]
      w-2;

      &--selected {
        @include option-filter-subject-selected($subject-map);
        @apply visible;
      }

      &--hovered {
        @include option-filter-subject-hover($subject-map);
        @apply visible;
      }
    }
  }
}
