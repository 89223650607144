.deaflix-share-buttons {
  @apply text-xs items-center bg-white rounded-br-11p flex h-85p left-0 my-0 max-w-none min-w-0 fixed top-52p w-auto max-sm:rounded-none max-sm:flex-wrap max-sm:h-auto max-sm:max-w-full max-sm:w-full;

  .deaflix-learning-object__header-divider {
    @apply bg-black flex-shrink-0;
  }

  &__element {
    @apply flex items-center h-full max-sm:py-25p max-sm:justify-center max-sm:mx-0 max-sm:w-full max-sm:basis-full relative;

    ~ .deaflix-learning-object__header-divider {
      @apply hidden sm:block;
    }

    &-button.dea-button {
      @apply items-center bg-white border-0 rounded-none flex justify-center px-25p w-full h-full;

      &:hover {
        @apply shadow-none underline;
      }

      .dea-button__label {
        @apply flex items-center justify-center gap-12p;

        span {
          @apply font-dea-bold text-xs;
        }

        .svg-logo {
          @apply h-lg aspect-square;
        }
      }
    }

    &:first-child {
      @apply bg-black w-245p;

      .deaflix-share-buttons__element-button.dea-button {
        @apply bg-black text-white h-full w-full;

        .dea-button__label,
        span {
          @apply text-white;
        }

        .dea-button__label {
          @apply max-sm:flex-col;

          [class^="classroom-share-button"] {
            @apply bg-white rounded-full h-64p aspect-square grid place-content-center;

            img {
              @apply w-38p;
            }
          }
        }
      }
    }

    &:last-child {
      .deaflix-share-buttons__element-button.dea-button {
        @apply bg-black rounded-small-content-buttons text-white h-36p mx-25p no-underline w-124p;

        &:hover {
          @apply bg-pink;
        }

        .dea-button__label {
          @apply flex-col;
        }

        .dea-button__label,
        span {
          @apply font-dea-bold text-sm text-white;
        }
      }
    }

    &--other-share {
      @apply max-sm:w-auto max-sm:mx-auto max-sm:border-b max-sm:border-black-22 max-sm:basis-auto;
    }
  }
}
