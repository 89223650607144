.deaflix-test-card {
  @apply border-b
  border-black
  border-opacity-20
  flex
  flex-col
  md:flex-row
  gap-5
  items-start
  justify-between
  mb-3
  md:mb-8
  pb-7
  w-full;

  &:hover {
    .deaflix-test-card__resource-title {
      @apply text-pink;
    }
  }

  &__resource-detail {
    @apply flex
    flex-row
    flex-1
    gap-4
    items-center;
  }

  &__resource-titles {
    @apply flex
    flex-col;
  }

  &__resource-title {
    @apply font-dea-bold
    text-base;
  }

  &__resource-subtitle {
    @apply font-dea-regular
    text-base;
  }

  &__resource-actions {
    @apply flex
    flex-row
    items-center
    justify-between;

    &:has(~ &) {
      @apply border-b
      border-black
      border-opacity-20
      mb-2
      pb-2.5;
    }

    &-container {
      @apply flex
      flex-col
      flex-1
      w-full;
    }

    &:hover {
      .deaflix-test-card__resource-divider {
        @apply bg-pink;
      }

      .deaflix-test-card__button {
        @apply bg-pink;
      }
    }
  }

  &__resource-info {
    @apply flex
    flex-row
    items-center
    gap-5;
  }

  &__resource-divider {
    @apply bg-black
    h-7
    rounded-2xl
    w-1.5
    relative;
  }

  &__resource-label {
    @apply border-r
    border-black
    pr-5
    text-base
    text-black;
  }

  &__resource-app {
    @apply font-dea-bold
    text-base
    text-black;
  }

  &__button {
    @extend .deaflix-content-button--small;
    @apply bg-black
    font-dea-medium
    text-white;
  }
}
