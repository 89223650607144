.deaflix-cards__filters {
  @apply bg-black
  bottom-0
  fixed
  flex
  flex-row-reverse
  h-[66px]
  justify-center
  px-10
  py-4
  right-0
  text-white
  w-full
  z-20
  md:bg-transparent
  md:flex-row
  md:h-auto
  md:justify-between
  md:mb-6
  md:p-0
  md:relative
  md:text-inherit;
}
