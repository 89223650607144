@use "sass:math";

.saperi-essenziali-main {
  @apply col-span-full;
}

.deaflix-subject-header {
  @apply grid
  md:h-60
  place-items-center
  w-full;

  &__content {
    @include deaflix-content-header-subject($subject-map);
    @apply after:content-none
    after:md:absolute
    after:md:content-[""]
    after:md:h-20
    after:md:rounded-2xl
    after:md:w-1.5
    after:right-3.5
    after:top-3.5
    before:content-none
    before:left-3.5
    before:md:absolute
    before:md:content-[""]
    before:md:h-20
    before:md:rounded-2xl
    before:md:w-1.5
    before:top-3.5
    max-md:flex
    max-md:flex-col
    max-md:items-center
    max-md:mb-4
    md:bg-white
    md:h-[111px]
    md:pl-[93px]
    md:pr-[85px]
    md:py-3.5
    md:relative
    md:rounded-subject-header
    md:shadow-subject-header
    max-md:z-0
    w-full
    max-md:-mt-[245px];

    &--default {
      @apply after:bg-default-primary
      before:bg-default-primary
      max-md:-mt-8
      shadow-default-shadow;

      .deaflix-subject-header {
        &__title {
          @apply font-dea-bold
          md:mb-2;

          .deaflix-icon {
            @apply max-md:hidden;
          }
        }

        &__subtitle {
          @apply max-md:hidden;

          &--text {
            @apply font-dea-medium
            text-base;
          }
        }

        &__image {
          @apply max-md:hidden;
        }
      }
    }
  }

  &__title {
    @apply flex
    font-dea-bold
    gap-3
    items-center
    max-md:h-20
    max-md:mb-0
    max-md:mt-0
    max-md:text-34
    max-md:text-center
    md:my-3.5
    md:text-30;

    &-icon {
      @apply inline
      max-md:hidden
      w-5;
    }
  }

  &__subtitle {
    @apply h-6
    max-md:h-auto;

    &--text,
    &--only-text {
      @apply font-dea-bold
      max-md:hidden
      text-base;
    }

    &--only-text {
      @apply font-dea-regular;

      &-strong {
        @apply font-dea-bold;
      }
    }

    &--icon {
      @apply inline
      w-5;
    }

    &--link {
      .deaflix-button {
        @apply hover:text-pink
        text-black;

        &:hover {
          .svg-icon svg {
            @apply fill-pink
            stroke-pink;
          }
        }
      }

      .svg-icon svg {
        @apply fill-black
        stroke-black;
      }

      .dea-card-resource__share-dropdown {
        @apply z-10;
      }
    }
  }

  &__image {
    @apply max-h-[227px]
    max-md:-mt-5
    md:absolute
    md:h-[244px]
    md:right-[84px]
    md:-top-[72px]
    inline-block;
  }
}
