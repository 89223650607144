.deaflix-search-filter {
  @include deaflix-search-filter($subject-map);
  @apply border-b-0
  flex
  flex-row
  items-center
  p-0
  w-fit
  md:border-b-[1px]
  md:px-2
  md:w-[78px];

  &--focused {
    @apply w-full
    md:w-[267px]
    lg:w-[316px]
    xl:w-[397px]
    2xl:w-[464px];
  }

  &__search {
    @apply h-[18px]
    min-w-[18px]
    mr-2;

    &-icon {
      @apply hidden
      md:block;

      &-mobile {
        @apply block
        md:hidden;
      }
    }
  }

  &__input {
    @apply bg-transparent
    cursor-pointer
    font-dea-regular
    placeholder-white
    text-sm
    w-0
    md:placeholder-black
    md:w-auto;

    &--focused {
      @apply bg-transparent
      w-full
      md:bg-inherit
      md:w-[267px]
      lg:w-[316px]
      xl:w-[397px]
      2xl:w-[464px];
    }

    &:focus-visible {
      @apply outline-none;
    }
  }

  &__close-icon {
    @apply cursor-pointer;

    &-svg {
      @apply hidden
      md:block;

      &-mobile {
        @apply block
        md:hidden;
      }
    }
  }
}
