.deaflix-sidebar {
  @apply flex
  flex-col-reverse
  h-auto
  md:block
  md:py-sidebar-padding
  md:self-start
  md:sticky
  md:top-[118px]
  md:w-sidebar
  pb-5
  pt-8
  transition-transform
  translate-y-0
  w-full
  z-[1];

  &__container {
    @apply bg-white
    md:bg-sidebar;
  }

  &--pink-sidebar {
    // sass-lint:disable space-after-colon
    @apply after:[clip-path:ellipse(60%_60%_at_bottom_center)]
    after:absolute
    after:bg-white
    after:bottom-0
    after:h-[85px]
    md:after:h-[0]
    after:w-full
    bg-subject-page-mobile
    md:bg-none
    h-[378px]
    justify-end
    md:content-none
    md:h-screen;
    // sass-lint:enable space-after-colon

    &.deaflix-sidebar--is-sticky {
      @apply after:content-none
      bg-none;
    }
  }

  &.deaflix-sidebar--is-sticky {
    @apply bg-pink
    fixed
    h-auto
    pb-1.5
    pt-2
    top-0
    z-20;

    .deaflix-sidebar__header {
      @apply hidden;
    }

    .deaflix-sidebar-button--active {
      .deaflix-sidebar-button__link-image {
        @apply after:-bottom-0.5
        after:-rotate-45
        after:absolute
        after:bg-transparent
        after:bg-white
        after:h-px
        after:left-[22px]
        after:rounded-sm
        after:w-2;
      }
    }

    .deaflix-sidebar-button__link-image {
      @apply h-auto;
    }

    .deaflix-sidebar-button__link-text {
      @apply text-white;
    }
  }

  &__header {
    @apply md:mb-9
    md:mt-0
    md:px-sidebar-padding
    md:text-left
    mt-4
    text-center;

    &-title {
      @apply font-dea-bold
      md:text-base
      text-black
      text-sm;
    }

    &-subtitle {
      @apply font-dea-regular
      hidden
      mt-1.5
      md:block
      text-black
      text-sm;
    }
  }

  &__navigation {
    @apply flex
    md:block;

    &-list {
      @apply flex
      md:block
      md:mx-0
      mx-auto;
    }
  }
}
