// sass-lint:disable indentation
$base-url: "..";

@font-face {
  font-family: "LarsseitThin";
  font-style: normal;
  font-weight: normal;
  src: url("#{$base-url}/assets/fonts/LarsseitThin.eot") format("embedded-opentype"),
    url("#{$base-url}/assets/fonts/LarsseitThin.woff2") format("woff2"),
    url("#{$base-url}/assets/fonts/LarsseitThin.woff") format("woff"),
    url("#{$base-url}/assets/fonts/LarsseitThin.ttf") format("truetype"),
    url("#{$base-url}/assets/fonts/LarsseitThin.svg#LarsseitThin") format("svg");
}
@font-face {
  font-family: "LarsseitLight";
  font-style: normal;
  font-weight: normal;
  src: url("#{$base-url}/assets/fonts/LarsseitLight.eot") format("embedded-opentype"),
    url("#{$base-url}/assets/fonts/LarsseitLight.woff2") format("woff2"),
    url("#{$base-url}/assets/fonts/LarsseitLight.woff") format("woff"),
    url("#{$base-url}/assets/fonts/LarsseitLight.ttf") format("truetype"),
    url("#{$base-url}/assets/fonts/LarsseitLight.svg#LarsseitLight") format("svg");
}
@font-face {
  font-family: "Larsseit";
  font-style: normal;
  font-weight: normal;
  src: url("#{$base-url}/assets/fonts/Larsseit.eot") format("embedded-opentype"),
    url("#{$base-url}/assets/fonts/Larsseit.woff2") format("woff2"),
    url("#{$base-url}/assets/fonts/Larsseit.woff") format("woff"),
    url("#{$base-url}/assets/fonts/Larsseit.ttf") format("truetype"),
    url("#{$base-url}/assets/fonts/Larsseit.svg#Larsseit") format("svg");
}

@font-face {
  font-family: "LarsseitMedium";
  font-style: normal;
  font-weight: normal;
  src: url("#{$base-url}/assets/fonts/LarsseitMedium.eot") format("embedded-opentype"),
    url("#{$base-url}/assets/fonts/LarsseitMedium.woff2") format("woff2"),
    url("#{$base-url}/assets/fonts/LarsseitMedium.woff") format("woff"),
    url("#{$base-url}/assets/fonts/LarsseitMedium.ttf") format("truetype"),
    url("#{$base-url}/assets/fonts/LarsseitMedium.svg#LarsseitMedium") format("svg");
}
@font-face {
  font-family: "LarsseitBold";
  font-style: normal;
  font-weight: normal;
  src: url("#{$base-url}/assets/fonts/LarsseitBold.eot") format("embedded-opentype"),
    url("#{$base-url}/assets/fonts/LarsseitBold.woff2") format("woff2"),
    url("#{$base-url}/assets/fonts/LarsseitBold.woff") format("woff"),
    url("#{$base-url}/assets/fonts/LarsseitBold.ttf") format("truetype"),
    url("#{$base-url}/assets/fonts/LarsseitBold.svg#LarsseitBold") format("svg");
}
@font-face {
  font-family: "LarsseitExtraBold";
  font-style: normal;
  font-weight: normal;
  src: url("#{$base-url}/assets/fonts/LarsseitExtraBold.eot") format("embedded-opentype"),
    url("#{$base-url}/assets/fonts/LarsseitExtraBold.woff2") format("woff2"),
    url("#{$base-url}/assets/fonts/LarsseitExtraBold.woff") format("woff"),
    url("#{$base-url}/assets/fonts/LarsseitExtraBold.ttf") format("truetype"),
    url("#{$base-url}/assets/fonts/LarsseitExtraBold.svg#LarsseitExtraBold") format("svg");
}

@font-face {
  font-family: "deascuola";
  font-style: normal;
  font-weight: normal;
  src: url("#{$base-url}/assets/fonts/deascuola.eot?#iefix") format("embedded-opentype"),
    url("#{$base-url}/assets/fonts/deascuola.woff") format("woff"),
    url("#{$base-url}/assets/fonts/deascuola.ttf") format("truetype"),
    url("#{$base-url}/assets/fonts/deascuola.svg#deascuola") format("svg");
}
// sass-lint:enable indentation
