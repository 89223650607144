.deaflix-test-page {
  .dea-modal {
    background-color: $dea-color-white;

    &-overlay {
      display: none;

      &--visible {
        background-color: rgba($dea-color-black, 0.8);
        bottom: 0;
        display: block !important; // sass-lint:disable-line no-important
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 999999;
      }
    }
  }
}
