$border-arrow--size: 7px;

.dea-tooltip__content {
  @apply font-dea-regular
  text-14;

  .dea-font-white {
    @apply text-white;
  }

  .dea-button {
    align-items: center;
    background-color: $dea-color-grey-dark;
    border: $border-light $dea-color-grey-dark;
    border-radius: $border-radius-medium;
    cursor: pointer;
    display: flex;
    justify-content: center;
    line-height: 0;
    min-width: 120px;
    outline: none;
    transition: all 0.3s ease-in;

    &__label {
      @apply font-dea-medium
      leading-6
      text-14
      text-white;
    }

    &.dea-button--small {
      min-height: $dea-button-size-small;
      padding: 5px 18px;

      .dea-button__label {
        transform: none;
      }

      .dea-button__icon {
        transform: none;
      }
    }
  }

  .dea-links {
    align-items: center;
    display: inline-flex;
    justify-content: flex-start;
    text-align: right;
    text-decoration: none;
    transition: opacity 0.3s;

    &:hover,
    &:focus {
      opacity: $opacity-focus;
    }
  }

  .dea-text--right {
    @apply text-right;
  }

  .dea-links__label {
    border-bottom: $border-bold $dea-color-grey-dark;
    // this is needed to keep the link simmetric - this is also true when border bottom is removed as to do this we only set it to transparent
    // !important to disable hover effect on border-top
    border-top: $border-bold transparent !important; // sass-lint:disable-line no-important
    color: inherit;
    display: inline-block;
    top: 0;
  }

  .dea-links--small {
    .dea-links__label {
      @apply font-dea-regular
      text-12;
    }
  }
}

.dea-tooltip__inner {
  background: $dea-color-grey-dark;
  border-radius: $border-radius-small;
  padding: $space-small;
  position: relative;
  width: 160px;

  &:after,
  &:before {
    border-color: transparent;
    border-width: $border-arrow--size;
    content: "";
    display: block;
    height: 0;
    margin: 0;
    pointer-events: none;
    position: absolute;
    width: 0;
  }

  &:after {
    border-style: solid;
    display: none;
  }

  &:before {
    border-color: $dea-color-grey-dark;
  }
}

.dea-tooltip__inner--negative {
  background: $dea-color-white;
  border: $border-light $dea-color-grey-medium-light;

  &:after {
    display: block;
  }
}

//TRIANGLE SHAPE AND COLOR

.dea-tooltip__inner--left {
  margin-left: $space-x-small;

  &:before {
    border-bottom: $border-thick transparent;
    border-right: $border-thick $dea-color-grey-dark;
    border-top: $border-thick transparent;
  }

  &.dea-tooltip__inner--negative {
    &:after {
      border-left: 0;
      border-right-color: $dea-color-white;
    }

    &:before {
      border-left: 0;
      border-right-color: $dea-color-grey-medium-light;
    }
  }
}

.dea-tooltip__inner--right {
  margin-right: $space-x-small;

  &:before {
    border-bottom: $border-thick transparent;
    border-left: $border-thick $dea-color-grey-dark;
    border-top: $border-thick transparent;
  }

  &.dea-tooltip__inner--negative {
    &:after {
      border-left-color: $dea-color-white;
      border-right: 0;
    }

    &:before {
      border-left-color: $dea-color-grey-medium-light;
      border-right: 0;
    }
  }
}

.dea-tooltip__inner--top {
  margin-top: $space-x-small;

  &:before {
    border-bottom: $border-thick $dea-color-grey-dark;
    border-left: $border-thick transparent;
    border-right: $border-thick transparent;
  }

  &.dea-tooltip__inner--negative {
    &:after {
      border-bottom-color: $dea-color-white;
      border-top: 0;
    }

    &:before {
      border-bottom-color: $dea-color-grey-medium-light;
      border-top: 0;
    }
  }
}

.dea-tooltip__inner--bottom {
  margin-bottom: $space-x-small;

  &:before {
    border-left: $border-thick transparent;
    border-right: $border-thick transparent;
    border-top: $border-thick $dea-color-grey-dark;
  }

  &.dea-tooltip__inner--negative {
    &:after {
      border-bottom: 0;
      border-top-color: $dea-color-white;
    }

    &:before {
      border-bottom: 0;
      border-top-color: $dea-color-grey-medium-light;
    }
  }
}

//TRIANGLE POSITION

.dea-tooltip__inner--left--top {
  &:after {
    left: -$space-x-small + 1px;
    top: $space-small + 1px;
  }

  &:before {
    left: -$space-x-small;
    top: $space-small;
  }
}

.dea-tooltip__inner--left--center {
  &:after {
    left: -$space-x-small + 1px;
    top: calc(50% - #{$space-x-small} + 1px);
  }

  &:before {
    left: -$space-x-small;
    top: calc(50% - #{$space-x-small});
  }
}

.dea-tooltip__inner--left--bottom {
  &:after {
    bottom: $space-small + 1px;
    left: -$space-x-small + 1px;
  }

  &:before {
    bottom: $space-small;
    left: -$space-x-small;
  }
}

.dea-tooltip__inner--right--top {
  &:after {
    right: -$space-x-small + 1px;
    top: $space-small + 1px;
  }

  &:before {
    right: -$space-x-small;
    top: $space-small;
  }
}

.dea-tooltip__inner--right--center {
  &:after {
    right: -$space-x-small + 1px;
    top: calc(50% - #{$space-x-small} + 1px);
  }

  &:before {
    right: -$space-x-small;
    top: calc(50% - #{$space-x-small});
  }
}

.dea-tooltip__inner--right--bottom {
  &:after {
    bottom: $space-small + 1px;
    right: -$space-x-small + 1px;
  }

  &:before {
    bottom: $space-small;
    right: -$space-x-small;
  }
}

.dea-tooltip__inner--top--left {
  &:after {
    left: $space-small + 1px;
    top: -$space-x-small + 1px;
  }

  &:before {
    left: $space-small;
    top: -$space-x-small;
  }
}

.dea-tooltip__inner--top--center {
  &:after {
    left: calc(50% - #{$space-x-small} + 1px);
    top: -$space-x-small + 1px;
  }

  &:before {
    left: calc(50% - #{$space-x-small});
    top: -$space-x-small;
  }
}

.dea-tooltip__inner--top--right {
  &:after {
    right: $space-small + 1px;
    top: -$space-x-small + 1px;
  }

  &:before {
    right: $space-small;
    top: -$space-x-small;
  }
}

.dea-tooltip__inner--bottom--left {
  &:after {
    bottom: -$space-x-small + 1px;
    left: $space-small + 1px;
  }

  &:before {
    bottom: -$space-x-small;
    left: $space-small;
  }
}

.dea-tooltip__inner--bottom--center {
  &:after {
    bottom: -$space-x-small + 1px;
    left: calc(50% - #{$space-x-small} + 1px);
  }

  &:before {
    bottom: -$space-x-small;
    left: calc(50% - #{$space-x-small});
  }
}

.dea-tooltip__inner--bottom--right {
  &:after {
    bottom: -$space-x-small + 1px;
    right: $space-small + 1px;
  }

  &:before {
    bottom: -$space-x-small;
    right: $space-small;
  }
}
