dea-top-header {
  @media screen and (max-width: 1280px) {
    height: $dea-header-mobile-height;
  }
  display: block;
  height: $dea-header-height;
  line-height: 1;
  width: auto;
  z-index: 1 !important; // sass-lint:disable-line no-important
}

.site-header {
  position: fixed;
  width: 100%;
  z-index: 99;

  & + .main-content {
    @media screen and (max-width: 1280px) {
      margin-top: $dea-header-main-mobile-height;
    }
    margin-top: $dea-header-main-height;
  }
}

.site-header--integrated-mobile + .main-content {
  @media screen and (max-width: 1280px) {
    margin-top: 0;
  }
}

.dea-main-header {
  @media screen and (max-width: 1280px) {
    display: none;
  }

  .dea-header--bottom__links--target-color-deaflix {
    &:hover {
      border-bottom-color: $dea-color-target-green-deaflix;
    }
  }
  background-color: $dea-color-white;
  border-bottom: $border-light $dea-header-grey;
  display: flex;

  &--dea {
    @media screen and (min-width: 1025px) {
      display: flex;
    }
  }

  &__dea-logo {
    border-right: $border-light $dea-header-grey;
    display: flex;
    padding: $space-xm-small $space-small;

    a {
      display: flex;
    }

    &-image {
      height: $dea-header-logo-height;
      max-width: inherit;
      width: auto;
    }

    &--extended {
      border-right: 0;
    }
  }

  &__main-title {
    @include vmargin(0, 0);
    font-family: $font-primary-bold;
    font-size: $h-font-size-mosaic-medium-text;
  }

  &__secondary-title {
    @include vmargin(0, 0);
    @include font-size($h-font-size-xx-small);
    font-family: $font-primary;
  }

  &__site-logo {
    @include hpadding($space-small, $space-small);
    align-self: center;
    display: flex;

    a {
      display: flex;
    }

    &-image {
      height: $deaflix-logo-height !important; //sass-lint:disable-line no-important
      max-width: inherit;
      width: auto;
    }
  }

  &__menu {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-right: $space-base;
    width: 100%;

    &-link {
      @include hpadding($space-x-small, $space-x-small);

      &.dea-border-target-deaflix {
        border-bottom-color: transparent !important; //sass-lint:disable-line no-important

        &.dea-border-target-deaflix:hover,
        &.dea-border-target-deaflix.active {
          border-bottom-color: $dea-color-target-green-deaflix !important; //sass-lint:disable-line no-important
        }
      }

      &.dea-main-header__menu-link--selected.dea-border-target-deaflix,
      &.dea-main-header__menu-link--ancestor.dea-border-target-deaflix {
        border-bottom-color: $dea-color-target-green-deaflix !important; //sass-lint:disable-line no-important
      }

      align-items: center;
      border-bottom: $border-bold-plus transparent;
      cursor: pointer;
      display: flex;
      height: 100%;

      &-label {
        @include font-size($h-font-size-x-small);
        color: $dea-color-black;
        font-family: $font-primary;
      }
    }
  }

  &__separator {
    background: $dea-color-grey-light;
    height: calc(100% - #{$space-small});
    width: 1px;

    &--transparent-header {
      background: $dea-color-white;
    }
  }

  .dea-links .dea-links__label {
    @include font-size($h-font-size-x-small);
    font-family: $font-primary;
  }

  &.dea-header--bottom-small .dea-links .dea-links__label {
    @include font-size($h-font-size-xx-small);
    font-family: $font-primary;
  }
}
