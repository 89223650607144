.deaflix-disclaimer-modal {
  @apply bg-black
  fixed
  flex
  flex-row
  font-dea-regular
  justify-between
  px-6
  py-4
  rounded-subject-header
  text-sm
  text-white
  translate-x-[50%]
  2xs:bottom-[79px]
  2xs:h-[133px]
  2xs:items-start
  2xs:min-w-[347px]
  2xs:right-[50%]
  2xs:w-[347px]
  xs:max-w-[415px]
  xs:w-[415px]
  sm:h-[97px]
  sm:max-w-[648px]
  sm:w-[648px]
  md:bottom-5
  md:h-[119px]
  md:items-center
  md:max-w-[714px]
  md:right-[calc(50/100*(100%-336px))]
  md:w-[714px]
  lg:h-[96px]
  lg:max-w-[847px]
  lg:w-[847px]
  xl:max-w-[1062px]
  xl:w-[1062px]
  2xl:max-w-[1244px]
  2xl:w-[1244px]
  z-10;

  &__message {
    @apply flex
    h-full
    2xs:flex-col
    2xs:items-start
    md:flex-row
    md:items-center;

    &-title {
      @apply border-white
      flex
      flex-row
      font-dea-bold
      h-full
      items-center
      mr-6
      pr-6
      text-base
      2xs:border-r-0
      2xs:mb-2
      md:border-r-[1px]
      md:mb-0;

      &-icon {
        @apply border-white
        2xs:border-r-[1px]
        2xs:mr-3
        2xs:pr-3
        md:border-r-0
        md:mr-5
        md:pr-0;
      }
    }

    &-body {
      @apply font-dea-regular
      2xs:text-xs
      md:text-sm;

      &-link {
        @apply font-dea-bold
        underline;
      }
    }
  }

  &__close {
    @apply bg-white
    cursor-pointer
    h-4
    min-w-[16px]
    rounded-full
    w-4;

    &-icon {
      @apply h-[10px]
      translate-x-[25%]
      translate-y-[25%]
      w-[10px];
    }
  }
}
