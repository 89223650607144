.activation-page {
  @apply bg-white flex fixed flex-col h-full left-0 top-0 w-full z-[100] overflow-y-auto;

  &__header {
    @apply flex items-center h-[72px] justify-between px-8 py-6 w-full;

    .dea-button {
      @apply border-0 #{!important}; // sass-lint:disable-line no-important

      &__icon {
        &:before {
          @apply text-26 #{!important}; // sass-lint:disable-line no-important
        }
      }
    }
  }

  &__content {
    @apply flex flex-col md:flex-row my-0 mx-auto pt-16;

    &-left {
      @apply md:p-0 md:pl-8 sm:pr-16 sm:pt-0 sm:p-16 px-8 pb-8 md:w-1/2;

      &-message {
        @apply mb-4;

        h1 {
          @apply mb-4;
        }
      }

      &-container-form {
        @apply sm:w-[65%];

        &-input {
          @apply w-full #{!important}; // sass-lint:disable-line no-important

          .dea-input--deaflix {
            @apply w-full #{!important}; // sass-lint:disable-line no-important
          }

          &-label {
            @apply text-end w-full;

            &-link {
              @apply cursor-pointer;
              @apply block #{!important}; // sass-lint:disable-line no-important
            }

            &-link {
              .dea-links__label {
                @apply border-b-pink #{!important}; // sass-lint:disable-line no-important
              }

              &:hover {
                + .activation-page__content-left-container-form-input-label-tooltip {
                  @apply inline-block #{!important}; // sass-lint:disable-line no-important
                }
              }
            }

            &-tooltip {
              @apply hidden text-end;
            }
          }
        }
      }
    }

    &-right {
      @apply sm:p-16 sm:pt-0 md:p-0 md:pl-16 md:pr-8 px-8 pb-8 md:w-1/2;

      h1 {
        @apply mb-4;
      }

      &-text {
        @apply mb-4 overflow-hidden;
      }
    }
  }
}
